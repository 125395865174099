// session-timer-initializer.ts
import { Injectable } from '@angular/core';
import { SessionTokenTimeoutService } from './shared/session-timeout.service';

@Injectable()
export class SessionTimerInitializer {
  constructor(private sessionTimerService: SessionTokenTimeoutService) {}

  initializeApp(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.sessionTimerService.restoreSessionTimer();
      resolve();
    });
  }
}
