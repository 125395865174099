import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../shared/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor( private localStorageService:LocalStorageService){}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.localStorageService.getItem('auth_app_token');
    // const groupId=this.localStorageService.getItem('group_ID');

    if (req.url.includes('/authenticationCallBack') || req.url.includes('/exportSourceScormPackage') 
        || req.url.includes('userValidation')) {
        //bypassing the authenticationCallBack
        // const cloned=req.clone({
        //   headers: req.headers.set('groupId',  groupId),
        // })
        return next.handle(req);
    }else if (req.url && token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
      return next.handle(cloned);
    } 
    // else {
    //   // No token and URL does not match bypass conditions, so continue with the request
    //   return next.handle(req);
    // }
  }

}
