<nb-card status="primary">
    <nb-card-header>
        <span>{{title}}</span>
        <!-- <button type="button" nbButton status="primary" class="close-btn" (click)="close()" 
                tabIndex="-1">
            <nb-icon icon="close-outline"></nb-icon>
        </button> -->
        <nb-icon class="close-button" icon="close-outline" (click)="onCloseButton()"></nb-icon>
        <!-- [options]="{ animation: { type: 'zoom' } }" -->
    </nb-card-header>
    <nb-card-body>
        <nb-icon class="infoIcon" icon="info-outline"></nb-icon>
        <span class="txt">{{description}}</span>
    </nb-card-body>
    <nb-card-footer>
        <div *ngIf="saveExportConfirm; else other">
            <button class="btn" nbButton status="primary" (click)="ok()">OK</button>
        </div>
        <ng-template #other>
            <button class="btn" nbButton status="primary" (click)="yes()">Yes</button>
            <button class="btn" nbButton status="primary" outline (click)="close()">No</button>
        </ng-template>
    </nb-card-footer>
</nb-card>