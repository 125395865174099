import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { ModuleService } from "../modules/module.service";
import { LocalStorageService } from "./localStorage.service";

@Injectable({
  providedIn: 'root'
})
export class SessionTokenTimeoutService {
  private sessionTimeoutSeconds: number = 0;
  private sessionTimeout$ = new BehaviorSubject<void>(null);
  private sessionTimeoutSubscription: Subscription | null = null

  constructor(
     private localStorageService: LocalStorageService,
     private moduleService: ModuleService,
     private msalService: MsalService,
     private router: Router,
  ) { 
    this.restoreSessionTimer();
  }

  public startSessionTimer(): void {
    const account = this.localStorageService.getItem('account');
    
    interval(1000).subscribe(() => {    
      if (this.sessionTimeoutSeconds > 0) {
        this.sessionTimeoutSeconds--;  
        // console.log(this.sessionTimeoutSeconds);
        this.saveSessionTimer();

        if (this.sessionTimeoutSeconds === 40) {
          this.handleSessionTimeout(account);
        }
      
        if (this.sessionTimeoutSeconds <= 0) {
          this.sessionTimeout$.next();
        }
      }
    });
  }

  public getSessionTimeout(): Observable<void> {
    return this.sessionTimeout$.asObservable();
  }

  public logout(): void {
    if (this.sessionTimeoutSubscription) {
      this.sessionTimeoutSubscription.unsubscribe(); // Unsubscribe to stop the timer
    }
    this.resetSessionTimer();
    this.saveSessionTimer();
  }

  private handleSessionTimeout(account: any): void {
    this.msalService.acquireTokenSilent({ account: account, scopes: ["openid", "profile"] }).subscribe((response) => {
      if (response) {
        this.resetSessionTimer();
        let requestVO = {
          accessToken: response.accessToken,
          idToken: response.idToken
        };
        this.moduleService.refreshToken(requestVO).subscribe((refreshTokenResponse) => {
          if (refreshTokenResponse) {
            this.localStorageService.setItem('auth_app_token', JSON.stringify(response.accessToken));
            this.startSessionTimer();
          }
        });
      }
    });
  }

  public resetSessionTimer(): void {
    this.sessionTimeoutSeconds = this.localStorageService.getItem('sessionTimeoutSeconds'); // Reset the session timeout value to 30 minutes    
  }

  private saveSessionTimer(): void {
    this.localStorageService.setItem('sessionTimeoutSeconds', this.sessionTimeoutSeconds.toString());
  }

  public restoreSessionTimer(): void {
    const storedSessionTimeout = this.localStorageService.getItem('sessionTimeoutSeconds');
    if (storedSessionTimeout) {
      this.sessionTimeoutSeconds = parseInt(storedSessionTimeout, 10);
    } else if (this.localStorageService.getItem('sessionTimeOut')) {
      const expiryTime = new Date(this.localStorageService.getItem('sessionTimeOut'));
      this.sessionTimeoutSeconds = Math.floor((expiryTime.getTime() - new Date().getTime()) / 1000);
    }
    this.startSessionTimer(); // Start the session timer after restoring
  }
}
