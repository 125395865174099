import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { BnNgIdleService } from 'bn-ng-idle';
import { SharedService } from '../../shared.service';
import { SessionTimeoutModalComponent } from '../session-timeout-modal.component';
import { MsalBroadcastService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {

  constructor(
    private router: Router,
    private bnIdle: BnNgIdleService,
    private dialogService: NbDialogService,
    private sharedService: SharedService,
    private msalBroadcastService: MsalBroadcastService,
    private localStorageService: LocalStorageService,
  ) { }

  sessionTimedOut() {
    //IF THE SCREEN IS KEPT IDLE FOR 5 MINS WHERE 60 = 1 MIN THEN SESSION TIMEOUT WILL START
    this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.localStorageService.setItem('idleTimer','started');
        if (!this.sharedService.modalRef) {
          this.dialogService.open(SessionTimeoutModalComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
          });
        }
      }
    });
  }

  checkTokenFailure(){
    this.msalBroadcastService.msalSubject$.subscribe({
      next: (msalSubject) => {
        console.log("msalSubject", msalSubject)
        if (msalSubject.eventType === 'msal:acquireTokenFailure') {
          this.router.navigate(['auth/login']);
          this.localStorageService.clear();
          sessionStorage.clear();
        }
      },
    });
  }
}
