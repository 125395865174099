import { Component } from '@angular/core';
import packageJSON from '../../../../../package.json';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by"></span>
    <span class="version-info">
        This software is proprietary and confidential | {{version}} | © PS Technology 2023
    </span>
    <div class="socials"></div>
  `,
})
export class FooterComponent {
  public version: string = packageJSON.buildVersion;
}
