import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, throwIfEmpty } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { param } from 'jquery';
import { Router } from '@angular/router';
import { LocalStorageService } from '../shared/localStorage.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { MsalService } from '@azure/msal-angular';
import { SessionTokenTimeoutService } from '../shared/session-timeout.service';


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  private URL: string = "/scenario-Builder/scenario-builder";
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private bnIdle: BnNgIdleService,
    private msalService: MsalService,
  ) { }
  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  };
  // If the error property is null this could be because the server does not return anything. Did 

  getAllScenarioTypes() {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/types`, { headers: header })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllScenarios() {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/scenarios`, { headers: header })
      .pipe(
        catchError(this.handleError)
      )
  }

  getDisplayNames() {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/scenarioDescription`, { headers: header })
      .pipe(
        catchError(this.handleError)
      )
  }

  getCarModels() {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/carModels`, { headers: header })
      .pipe(
        catchError(this.handleError)
      )
  }

  getComponents(data: any) {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/carComponents`, { headers: header, params: data })
      .pipe(catchError(this.handleError))
  }

  getComponentsWithDefects(data: any) {
    // let header = new HttpHeaders();
    // header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .post<any[]>(`${this.URL}/carComponentsWithDefects`, data)
      .pipe(catchError(this.handleError))
  }

  fetchMultipleCarComponents(data: any) {
    return this
      .httpClient
      .post<any[]>(`${this.URL}/fetchMultipleCarComponents`, data)
      .pipe(catchError(this.handleError))
  }

  getCarDefects(data: any) {
    // let header = new HttpHeaders();
    // header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .post<any[]>(`${this.URL}/carDefects`, data)
      .pipe(catchError(this.handleError))
  }

  getAuthors() {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/authors`, { headers: header })
      .pipe(
        catchError(this.handleError)
      )
  }

  getScenarioIDs() {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/scenarioIds`, { headers: header })
      .pipe(
        throwIfEmpty(() => {
          return throwError("No Records Found");
        })
        , catchError(this.handleError)
      )
  }

  filterData(data: any) {
    return this
      .httpClient
      .post<any[]>(`${this.URL}/filter`, data)
      .pipe(catchError(this.handleError))
  }

  saveScenario(data: any) {
    return this
      .httpClient
      .post(`${this.URL}/save`, data)
      .pipe(catchError(this.handleError))
  }

  deleteScenario(data: any) {
    return this
      .httpClient
      .delete(`${this.URL}/deleteScenario`, { responseType: 'text', params: data })
      .pipe(catchError(this.handleError))
  }

  updateScenario(data: any) {
    return this
      .httpClient
      .put(`${this.URL}/updateScenario`, data)
      .pipe(catchError(this.handleError))
  }

  getScenarioDetails(data: any) {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .get<any[]>(`${this.URL}/scenarioDetails`, { headers: header, params: data })
      .pipe(catchError(this.handleError))
  }

  authenticationCallBack(data: any) {
    const header = new HttpHeaders();
    console.log(data);
    return this
      .httpClient
      .post(`${this.URL}/authenticationCallBack`, data,{observe:'response'})
      .pipe(catchError(this.handleError));
  }

  logoutCallBack() {
    // let header = new HttpHeaders();
    // header = header.set('Content-Type', 'application/json; charset=utf-8');
    return this
      .httpClient
      .post(`${this.URL}/logoutCallBack`,{observe:'response'})
      .pipe(catchError(this.handleError));
  }


  refreshToken(data: any) {
    const header = new HttpHeaders();
    return this
      .httpClient
      .post(`${this.URL}/refreshToken`, data)
      .pipe(catchError(this.handleError));
  }

  exportScormPackage(jsonData: any) {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json;');
    return this
      .httpClient
      .post(`${this.URL}/exportScormPackage`, jsonData, { headers: header, responseType: 'blob' })
      .pipe(catchError(this.handleError));
  }

  exportSourceScormPackage() {
    return this
      .httpClient
      .get(`${this.URL}/exportSourceScormPackage`, { responseType: 'blob' })
      .pipe(catchError(this.handleError));
  }

  userValidation(data:any){
    const header = new HttpHeaders();
    console.log(data);
    return this
      .httpClient
      .post(`${this.URL}/userValidation`, data)
      .pipe(catchError(this.handleError));
  }
}