<div class="header-container">
  <div class="logo-container">
    <img class="logo-img" src="assets/images/logo.png">
    <!-- <h4 class="title">SCENARIO BUILDER</h4> -->
    <h4 class="title">Scenario Builder</h4>

    <div class="strip">
      <a class="logo">{{pageTitle}}</a>
      <!-- <a class="logo" href="#" (click)="navigateHome()">{{pageTitle}}</a> -->
    </div>
  </div>
</div>

<div>
  <div class="user-details">
    <div class="col">
      <label>Tenant: </label>
      <span [nbTooltip]="tooltipTenant" [nbTooltipDisabled]="tooltipTenantDisabled">{{tenant}}</span>
    </div>
    <div class="col">
      <label>Username: </label>
      <span [nbTooltip]="tooltipUserName" [nbTooltipDisabled]="tooltipUsernameDisabled">{{username}}</span>
    </div>
    <div class="col">
      <label>UserId: </label>
      <span [nbTooltip]="tooltipEmail" [nbTooltipDisabled]="tooltipEmailDisabled">{{email}}</span>
    </div>
  </div>

  <!-- <nb-user class=" user" size="large" [name]="user?.account.name" [title]="" color="#ffffff" onlyPicture
        [nbContextMenu]="userMenu" nbContextMenuTrigger="click" nbContextMenuPlacement="bottom">
        </nb-user> -->

  <!-- <button class="btn" nbButton size="small" (click)="onLogout()">Log Out</button> -->
  <nb-actions size="medium">
    <nb-action icon="power-outline" (click)="onLogout()" nbTooltip="Logout"></nb-action>
  </nb-actions>
</div>