import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { BnNgIdleService } from 'bn-ng-idle';
import { SharedService } from '../shared.service';
import { LocalStorageService } from '../localStorage.service';

@Component({
  selector: 'ngx-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss']
})
export class SessionTimeoutModalComponent implements OnInit {

  counter: any;
  public timerInterval: any;

  constructor(
    protected dialogRef: NbDialogRef<SessionTimeoutModalComponent>,
    private bnIdle: BnNgIdleService,
    private router: Router,
    private sharedService: SharedService,
    private localStorageService: LocalStorageService,
  ) { 
    this.sharedService.modalRef = this.dialogRef;
  }

  ngOnInit(): void {
    this.timer(1);
  }

  timer(minute: number) {
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else {
        textSec = statSec
      };

      this.counter = seconds;

      if (seconds == 0) {
        clearInterval(this.timerInterval);
        this.endSession();
      }
    }, 1000);
  }

  continueSession() {
    this.dialogRef.close();
    this.sharedService.modalRef = null;
    clearInterval(this.timerInterval)
  }

  endSession() {
    this.dialogRef.close();
    this.sharedService.modalRef = null;
    clearInterval(this.timerInterval);
    this.localStorageService.clear();
    this.router.navigate(['auth/login']);
    this.bnIdle.stopTimer();
  }
}
