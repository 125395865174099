import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthJWTToken, NbAuthModule, NbAuthSimpleToken, NbDummyAuthStrategy, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
} from './utils';
import { MockDataModule } from './mock/mock-data.module';


const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

const loginErrorMsg = 'UserDetailsService returned null, which is an interface contract violation';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    
    // strategies: [
    //   NbPasswordAuthStrategy.setup({
    //     name: 'email',
    //     baseEndpoint: '',
        
    //     token: {
    //       class: NbAuthJWTToken,
    //       key: 'auth_app_token', // this parameter tells where to look for the token
    //     },
    //     login: {
    //       alwaysFail: false,
    //       requireValidToken: true,
    //       endpoint: '/scenario-Builder/scenario-builder/authenticationCallBack',
    //       method: 'post',
    //       redirect: {
    //         success: '/modules/dashboard',
    //         failure: 'null',
    //       },
    //       //defaultErrors: ['Invalid Credentials, Please try again.'],
    //       defaultMessages: ['You have been successfully logged in.'],
    //     },
    //     errors: {
    //       getter: (module, res, options) => {
    //         if(res.error.text === loginErrorMsg){
    //           res.error.text = "Username not found"
    //         }
    //         return res.error.text;
    //       },
    //     },
    //   }),
    // ],
    // forms: {
    //   login: {
    //     //redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
    //    // strategy: 'auth',  // strategy id key.
    //     rememberMe: false,   // whether to show or not the `rememberMe` checkbox
    //     showMessages: {     // show/not show success/error messages
    //       success: false,
    //       error: true,
    //     },},
    //   register: {
    //     socialLinks: socialLinks,
    //   },
    // },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
