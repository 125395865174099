import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {
    key: string;
    secretKey = "ErBIGOR;TCdK(;8_X:=*I'-H,e?Ou,1";
    localStorageEncryption: boolean = true;
    constructor() {
    }

    /**
     * Sets item in LocalStorage
     * @param {string} key 
     * @param {object} value 
     */

    setItem(key: string, value: any) {
        if (key && value && value != "") {
            if (this.localStorageEncryption) {
                let encData = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();
                let encryptedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Latin1.parse(encData));
                localStorage.setItem(key, encryptedData);
                return;
            }
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, value);
        }
    }

    /**
     * Returns specific item from LocalStorage
     * @param {string} key 
     */
    getItem(key: string) {
        if (key) {
            let data: any = window.localStorage.getItem(key);
            if (data && data != null && data != undefined && data != "") {
                try {
                    let decData = CryptoJS.enc.Base64.parse(data.toString()).toString(CryptoJS.enc.Latin1);
                    let decrypt = CryptoJS.AES.decrypt(decData, this.secretKey);
                    let decryptedData = decrypt.toString(CryptoJS.enc.Utf8);
                    if (decryptedData) {
                        return decryptedData && JSON.parse(decryptedData);
                    } else {
                        if (typeof data == 'number') {
                            return data
                        } else {
                            if (this.isJson(data)) {
                                return JSON.parse(data);
                            } else {
                                return data;
                            }
                        }
                    }
                }
                catch (e) {
                    if (typeof data == 'number') {
                        return data
                    } else {
                        if (this.isJson(data)) {
                            return JSON.parse(data);
                        } else {
                            return data;
                        }
                    }
                }
            } else {
                return data;
            }
        }
        return null;
    }

    /**
     * Sets item in LocalStorage
     * @param {string} key 
     * @param {object} value 
     */

    setSessionItem(key: string, value: any) {
        if (key && value && value != '') {
            if (this.localStorageEncryption) {
                let encData = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();
                let encryptedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Latin1.parse(encData));
                window.sessionStorage.setItem(key, encryptedData);
            } else {
                window.sessionStorage.setItem(key, JSON.stringify(value));
            }
        } else { window.sessionStorage.setItem(key, value); }
    }

    /**
     * Returns specific item from session storage
     * @param {string} key 
     */
    getSessionItem(key: string) {
        if (key) {
            let data = window.sessionStorage.getItem(key);
            if (data != null && data != undefined) {
                try {
                    let decData = CryptoJS.enc.Base64.parse(data.toString()).toString(CryptoJS.enc.Latin1);
                    let decrypt = CryptoJS.AES.decrypt(decData, this.secretKey);
                    let decryptedData = decrypt.toString(CryptoJS.enc.Utf8);
                    if (decryptedData) {
                        return decryptedData && JSON.parse(decryptedData);
                    } else {
                        if (this.isJson(data)) {
                            return JSON.parse(data);
                        } else {
                            return data;
                        }
                    }
                }
                catch (e) {
                    if (this.isJson(data)) {
                        return JSON.parse(data);
                    } else {
                        return data;
                    }
                }
            }
            return data;
        }
    }

    clear() {
        localStorage.clear();
    }
    removeItem(arg0: string) {
        localStorage.removeItem(arg0);
    }

    isJson(str: any) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}