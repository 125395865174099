/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { HttpClient } from '@angular/common/http';
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalQAConfig: Configuration = {
    auth: {
        clientId: '261e1eb5-2224-48e3-98d4-4d17dccd24de', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/d26f7f07-b76e-46fc-a64e-aaeb3b48a77a', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: 'https://appsimqa001.azurewebsites.net/scenario-builder/modules/dashboard', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: 'https://appsimqa001.azurewebsites.net/scenario-builder', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["user", "read"],
    loginHint: "example@domain.net"
};