import { Injectable } from '@angular/core';
import { CarComponent } from '../modules/model/model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private _scenarioData: any = [];
  _scenarios: any = [];
  private _freightAnatomyFlag = false;
  private _locoMotiveFlag = false;
  private _airBrakeTestFlag = false;
  private _scenarioCreation = false;

  private scenarioTypeDetails: any = [];
  private editDataDashboard: any = [];
  private newSelectedTableData: any = [];

  modalRef: any;

  constructor() { }

  public getscenarioData(): any {
    return this._scenarioData;
  }
  public setscenarioData(value: any) {
    this._scenarioData = value;
  }

  public getFreightAnatomyFlag() {
    return this._freightAnatomyFlag;
  }
  public setFreightAnatomyFlag(flag: boolean) {
    this._freightAnatomyFlag = flag;
  }

  public getLocoMotiveFlag() {
    return this._locoMotiveFlag;
  }
  public setLocoMotiveFlag(flag: boolean) {
    this._locoMotiveFlag = flag;
  }

  public getAirBrakeTestFlag() {
    return this._airBrakeTestFlag;
  }
  public setAirBrakeTestFlag(flag: boolean) {
    this._airBrakeTestFlag = flag;
  }

  public getScenarioCreation() {
    return this._scenarioCreation;
  }
  public setScenarioCreation(flag: boolean) {
    this._scenarioCreation = flag;
  }

  public getScenarioTypeDetails() {
    return this.scenarioTypeDetails;
  }
  public setScenarioTypeDetails(data: any[]) {
    this.scenarioTypeDetails = data;
  }
  public resetScenarioTypeDetails() {
    this.scenarioTypeDetails = [];
  }

  public getEditDataFromDashboard() {
    return this.editDataDashboard;
  }
  public setEditDataFromDashboard(data: any[]) {
    this.editDataDashboard = data;
  }
  public resetEditDashboardData() {
    this.editDataDashboard = [];
  }

  public setNewSelectedTableData(data: any[]) {
    this.newSelectedTableData = data;
  }
  public getNewSelectedTableData() {
    return this.newSelectedTableData;
  }

  //original code
  // onDefectSelection(selectedData: any[], rowData: any, defect: any) {
  //   const existingIndex = selectedData.findIndex(
  //     //componentId
  //     (item) => item.carComponentID === rowData.carComponentID
  //   );

  //   if (existingIndex !== -1) {
  //     const existingData = selectedData[existingIndex];
  //     existingData.selectedDefect = existingData.selectedDefect === defect.carDefectsID ? null : defect.carDefectsID;
  //     if (existingData.selectedDefect === null) {
  //       selectedData.splice(existingIndex, 1);
  //     }
  //   } else {
  //     // Remove any other selected options for the same component
  //     selectedData = selectedData.filter(
  //       (item) => item.carComponentID !== rowData.carComponentID
  //     );

  //     rowData.selectedDefect = defect.carDefectsID;
  //     selectedData.push(rowData);
  //   }

  //   // console.log("selectedData from sharedService", selectedData)
  //   return selectedData;
  // }


  //new logic
  onDefectSelection(selectedData: any[], rowData: any, defect: any) {
    const existingIndex = selectedData.findIndex(
      (item) => item.carComponentID === rowData.carComponentID
    );

    if (existingIndex !== -1) {
      const existingData = selectedData[existingIndex];

      if (existingData.selectedDefect === defect.carDefectsID) {
        if (rowData.animated) {
          // If the user clicks on the same defect, do nothing
          return selectedData;
        } else {
          // If user clicks on the same defect from defective comp section then deselect it 
          existingData.selectedDefect = null;
          selectedData.splice(existingIndex, 1);
        }
      } else {
        // Switch to a different defect
        existingData.selectedDefect = defect.carDefectsID;
      }
    } else {
      // If the component is not in the selectedData, create a new entry
      rowData.selectedDefect = defect.carDefectsID;
      selectedData.push(rowData);
    }

    return selectedData;
  }

  sortDefectsByDefaultSelectable(tableData) {
    tableData.forEach(row => {
      row.carDefects.sort((a, b) => {
        if (a.defaultSelectable === true && b.defaultSelectable !== true) {
          return -1;
        } else if (a.defaultSelectable !== true && b.defaultSelectable === true) {
          return 1;
        } else {
          // If defaultSelectable is the same for both items, sort by carDefectsID.
          return a.carDefectsID - b.carDefectsID;
        }
      });
    });
    return tableData;
  }

  getTotalRecordsForDefectiveComp(tableDataSource) {
    const defectiveCompLen = tableDataSource.filter(data => !data.animated).length;
    return defectiveCompLen;
  }

  getTotalRecordsForInteractiveComp(tableDataSource) {
    const interactiveCompLen = tableDataSource.filter(data => data.animated & data.show).length;
    return interactiveCompLen;
  }

  updateSelectedDefectOption(selectedTableData, selectedDataForInteractiveComp, scenarioDetailsData, tableDataSource) {
    selectedDataForInteractiveComp = [];
    selectedTableData = [];

    if (scenarioDetailsData && scenarioDetailsData.carDefects) {
      tableDataSource.forEach(rowData => {
        if (rowData.animated) {
          rowData.carDefects.forEach(carDfctData => {
            scenarioDetailsData.carDefects.forEach(scDetailsDefects => {
              if (carDfctData.carDefectsID === scDetailsDefects.carDefectsID) {
                rowData.selectedDefect = scDetailsDefects.carDefectsID;
                selectedDataForInteractiveComp.push(rowData);
              }
            });
          });
        } else {
          rowData.carDefects.forEach(carDfctData => {
            scenarioDetailsData.carDefects.forEach(scDetailsDefects => {
              if (carDfctData.carDefectsID === scDetailsDefects.carDefectsID) {
                rowData.selectedDefect = scDetailsDefects.carDefectsID;
                selectedTableData.push(rowData);
              }
            });
          });
        }
      });

      // if (this.selectedTableData.length === this.tableDataSource.length) {
      //   this.selectAll = true;
      // } else {
      //   this.selectAll = false;
      // }

      return {
        updatedSelectedTableData: selectedTableData,
        updatedSelectedDataForInteractiveComp: selectedDataForInteractiveComp
      };
    }
  }

  preSelectInteractiveComponentOptions(editMode, selectedDataForInteractiveComp, tableDataSource, scenarioDetailsData) {
    let interactiveComp = [];
    if (editMode) {
      const animatedData = tableDataSource.filter(data => data.animated);
      if (animatedData && animatedData.length > 0) {
        const scDetailsData = scenarioDetailsData.carDefects.filter(defcts => defcts.animated);
        if (scDetailsData.length > 0) {
          interactiveComp = animatedData.filter(animatedData => {
            const matchingRow = scDetailsData.find(scData => scData.carModelId === animatedData.carModelId);
            return !matchingRow;
          });
          console.log("filteredData", interactiveComp)
        }
      }
    } else {
      interactiveComp = tableDataSource.filter(data => data.animated);
      console.log("preselective comp", interactiveComp)
    }

    if (interactiveComp && interactiveComp.length > 0) {
      interactiveComp.forEach(comp => {
        comp.carDefects.forEach(defects => {
          if (defects.defaultSelectable) {
            comp.selectedDefect = defects.carDefectsID;
            selectedDataForInteractiveComp.push(comp);
          }
        });
      });
    }

    return selectedDataForInteractiveComp;
  }

  addInteractiveCompDataToSelectedTableData(selectedDataForInteractiveComp, selectedTableData) {
    selectedDataForInteractiveComp.forEach((interactiveData) => {
      const isDuplicate = selectedTableData.some((tableData) =>
        tableData.carModelId === interactiveData.carModelId &&
        tableData.selectedDefect === interactiveData.selectedDefect
      );

      if (!isDuplicate) {
        selectedTableData.push(interactiveData);
      }
    });
    console.log("Updated selectedTableData: ", selectedTableData);
    return selectedTableData;
  }

  prepareResponseData(defectData) {
    let preparedDefectData = [];
    const groupedData = defectData.reduce((acc: Record<string, CarComponent>, curr) => {
      const { carComponentID, show, animated, defaultSelectable, carComponentDescription, carComponentSummary, carDefectsDescription, carDefectsID, defectModelName, loopBackAnimation, carModelDescription, carModelId, unityGameObjectPath } = curr;

      // Create a compound key based on carComponentID and animated
      const key = `${carComponentID}_${animated}`;

      if (!acc[key]) {
        acc[key] = {
          carComponentID,
          show,
          animated,
          carComponentDescription,
          carComponentSummary,
          carModelId,
          carModelDescription,
          carDefects: [],
          unityGameObjectPath,
        };
      }

      acc[key].carDefects.push({
        carDefectsDescription,
        carDefectsID,
        animated,
        defaultSelectable,
        loopBackAnimation,
        defectModelName
      });

      return acc;
    }, {});

    // convert the groups object back to an array
    preparedDefectData = Object.values(groupedData);
    return preparedDefectData;
  }

  onSelectAllData(event, selectedTableData, tableDataSource) {
    console.log(event)
    selectedTableData = [];
    if (event) {
      // selectedTableData = tableDataSource;
      // selectedDataForInteractiveComp = [];
      tableDataSource.forEach(tblRowData => {
        if (!tblRowData.animated) {
          // tblRowData.selectedDefect = tblRowData.carDefects[0].carDefectsID;
          // selectedTableData.push(tblRowData);

          tblRowData.carDefects.forEach(defects => {
            if (defects.defaultSelectable) {
              tblRowData.selectedDefect = defects.carDefectsID;
              selectedTableData.push(tblRowData);
            }
          });
        }
      });
    }
    // else {
    //   // selectedTableData = [];
    //   // selectedDataForInteractiveComp = [];
    //   tableDataSource.forEach(tblRowData => {
    //     if (!tblRowData.animated) {
    //       tblRowData.selectedDefect = null;
    //     }
    //   });
    // }

    return {
      selectedTableData: selectedTableData,
      // selectedDataForInteractiveComp: selectedDataForInteractiveComp,
      tableDataSource: tableDataSource
    }
  }

  clearData(event, selectedTableData, tableDataSource) {
    console.log(event)
    selectedTableData = [];
    if (!event) {
      tableDataSource.forEach(tblRowData => {
        if (!tblRowData.animated) {
          tblRowData.selectedDefect = null;
        }
      });
    }

    return {
      selectedTableData: selectedTableData,
      tableDataSource: tableDataSource
    }
  }

  generateCenteredHeader(doc, text) {
    const fontSize = doc.internal.getFontSize();
    const pageWidth = doc.internal.pageSize.width;
  
    // Calculate the width of the text
    const textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
  
    // Calculate the x-position to center the text
    const xPosition = (pageWidth - textWidth) / 2;

    return xPosition;
  } 
}
