import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, take, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { NbAuthJWTToken, NbAuthService, NbAuthSimpleToken } from '@nebular/auth';
import { HeaderTitleService } from '../../../shared/headerTitle.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ModuleService } from '../../../modules/module.service';
import { LocalStorageService } from '../../../shared/localStorage.service';
import { formatDate } from '@angular/common';
import { SessionTokenTimeoutService } from '../../../shared/session-timeout.service';

const TENANT_USERNAME_MAXLEN = 30;
const EMAIL_MAXLEN = 35;

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  pageTitle: string = '';

  tenant: string;
  username: string = '';
  email: string = '';
  tooltipTenant: string;
  tooltipUserName: string;
  tooltipEmail: string;
  tooltipTenantDisabled: boolean = true;
  tooltipUsernameDisabled: boolean = true;
  tooltipEmailDisabled: boolean = true;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
  ];

  currentTheme = 'Light';

  userMenu = [{ title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private router: Router,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private headerTitleService: HeaderTitleService,
    private msalService: MsalService,
    private moduleSerivce: ModuleService,
    private localStorageService: LocalStorageService,
    private sessionTimeOut: SessionTokenTimeoutService,
    private bnIdle: BnNgIdleService) {

    // this.authService.onTokenChange()
    //   .subscribe((token: NbAuthJWTToken) => {
    //     if (token.isValid()) {
    //       this.user = token.getPayload().user; // receive payload from token and assign it to our `user` variable
    //       console.log("User from constructor method ", this.user)
    //     }
    //   });
  }

  onItemSelection(title) {
    if (title === 'Log out') {
      this.moduleSerivce.logoutCallBack().pipe(take(1)).subscribe((response) => {
        console.log(response);
        if (response) {
          let timerFlag=this.localStorageService.getItem('idleTimer');
          this.router.navigate(['auth/login']);
          sessionStorage.clear();
          if(timerFlag && timerFlag === 'started'){
            this.bnIdle.stopTimer();
          }        
          this.localStorageService.clear();
          this.msalService.logout();
          this.sessionTimeOut.resetSessionTimer();
        }
      })
    }
  }

  ngOnInit() {
    sessionStorage.clear();
    this.user = this.localStorageService.getItem('user');
    this.tenant = this.localStorageService.getItem('tenant_ID');
    console.log("User ", this.user)
    console.log("Tenant ", this.tenant)
    console.log("Token expiration time ", formatDate(this.user.expiresOn, 'MM/dd/yyyy HH:mm', 'en-US'));

    if (this.user) {
      this.username = this.user.account.name;
      this.email = this.user.account.username;

      if (this.tenant && this.tenant.length > TENANT_USERNAME_MAXLEN) {
        const slicedTenant = this.tenant.slice(0, TENANT_USERNAME_MAXLEN);
        this.tooltipTenant = this.tenant;
        this.tenant = slicedTenant + '...';
        this.tooltipTenantDisabled = false;
      }

      if (this.username.length > TENANT_USERNAME_MAXLEN) {
        const slicedUsername = this.username.slice(0, TENANT_USERNAME_MAXLEN);
        this.tooltipUserName = this.username;
        this.username = slicedUsername + '...';
        this.tooltipUsernameDisabled = false;
      }

      if (this.email.length > EMAIL_MAXLEN) {
        const slicedEmail = this.email.slice(0, EMAIL_MAXLEN);
        this.tooltipEmail = this.email;
        this.email = slicedEmail + '...';
        this.tooltipEmailDisabled = false;
      }
    }

    this.menuService.onItemClick().subscribe((event) => {
      this.onItemSelection(event.item.title);
    })
    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.headerTitleService.title.subscribe(updatedTitle => {
      this.pageTitle = updatedTitle;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    //this.menuService.navigateHome();
    this.router.navigate(['modules/dashboard']);
    return false;
  }

  onLogout() {
    this.moduleSerivce.logoutCallBack().pipe(take(1)).subscribe((response) => {
      console.log(response);
      if (response) {
        let timerFlag=this.localStorageService.getItem('idleTimer');
        this.router.navigate(['auth/login']);
        sessionStorage.clear();
        if(timerFlag && timerFlag === 'started'){
          this.bnIdle.stopTimer();
        }        
        this.localStorageService.clear();
        this.msalService.logout();
        this.sessionTimeOut.resetSessionTimer();
      }
    })
  }

}
