<nb-card status="primary">
    <nb-card-header>
        Session timeout due to Inactivity
    </nb-card-header>
    <nb-card-body>
        <span>Your session is going to end in {{counter}} second(s) and you will be redirected to the landing
            page.</span>
    </nb-card-body>
    <nb-card-footer>
        <button class="btn" nbButton status="primary" (click)="continueSession()">Continue</button>
        <button class="btn" nbButton status="primary" outline (click)="endSession()">End</button>
    </nb-card-footer>
</nb-card>