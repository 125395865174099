import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  title: string;
  description: string;
  saveExportConfirm: boolean = false;

  constructor(protected dialogRef: NbDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit(): void {
    this.saveExportConfirm = this.title && (this.title.includes('Save') || this.title.includes('Export')) ? true : false;
  }

  yes(){
    this.dialogRef.close(true);
  }

  close(){
    this.dialogRef.close(false);
  }

  onCloseButton(){
    console.log("Clicked close button");
    this.dialogRef.close();
  }

  ok(){
    this.dialogRef.close();
  }
}
