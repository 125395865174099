import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { filter, switchMap } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable, of } from 'rxjs';
import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class CustomMSALGuard implements CanActivate {

  constructor(
    private msalService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => {
          if (this.msalService.instance.getAllAccounts().length > 0) {
            return of(true);
          }

          // console.log(state.url)
          // this.authService.redirectUrl = state.url;
          this.router.navigate(['auth/login']);
          return of(false);
        })
      );
  }
}